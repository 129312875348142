body {
    margin: 0;
    background: #eeeeee;
}

@font-face {
    font-family: 'Gilmer Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Regular'), url('../assets/fonts/gilmer-regular.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Bold'), url('../assets/fonts/gilmer-bold.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Heavy'), url('../assets/fonts/gilmer-heavy.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Light'), url('../assets/fonts/gilmer-light.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Outline'), url('../assets/fonts/gilmer-outline.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Medium'), url('../assets/fonts/gilmer-medium.woff') format('woff');
}
